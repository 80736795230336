<style scoped>
.main {
    min-width: 1200px;
    padding: 10px 100px;
}
ul {
    padding-left: 20px;
}
li {
    padding-top: 6px;
}
.active {
    font-weight: bold;
    color: #0074a3;
}
a:hover {
    color: #0074a3;
}
a {
    cursor: pointer;
}
</style>

<template>
    <div>
        <Header />
        <div class="main">
            <el-tabs type="border-card" v-model="selectedTopicId" @tab-click="handleClick">
                <el-tab-pane v-for="topic in topicList" :key="topic.id" :label="topic.title" :name="topic.id">
                    <el-container>
                        <el-aside width="300px" style="padding-right: 10px; border-right: 2px solid #eaeaea">
                            <ul style="list-style-type: disclosure-open; margin-top: 0px">
                                <li v-for="chapter in topic.chapter_list" :key="chapter.id">
                                    <a :id="chapter.id" @click="listNote(chapter)">{{ chapter.title }}</a>
                                    <ul style="list-style-type: disc">
                                        <li v-for="note in chapter.note_list" :key="note.id">
                                            <a @click="getNoteById(note.id)" :id="note.id">{{ note.title }}</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </el-aside>
                        <el-main style="padding: 10px 40px">
                            <div :id="topic.id" v-loading="loading" element-loading-text="加载中......"></div>
                        </el-main>
                    </el-container>
                </el-tab-pane>
            </el-tabs>
        </div>
        <Footer />
    </div>
</template>

<script>
//代码高亮
import "highlight.js/styles/googlecode.css";

import { apiListTopicAndChapterAndNote } from "@/api/public/topic.js";
import { apiGetFisrtNoteByTopicId, apiGetNoteById } from "@/api/public/note.js";

import Header from "../common/Header.vue";
import Footer from "../common/Footer.vue";

export default {
    //注册组件
    components: {
        Header,
        Footer
    },
    data() {
        return {
            selectedTopicId: "",
            topicList: [],
            loading: true,
            selectedDOMID: ""
        };
    },
    methods: {
        // 获取文章列表
        async listTopic() {
            try {
                const response = await apiListTopicAndChapterAndNote();
                if (response.data.code == 200) {
                    this.topicList = response.data.data;
                    // console.log(this.topicList);
                    if (this.topicList.length > 0) {
                        //如果url参数带有topicId则打开对应的标签页
                        if (this.$route.query.topicId) {
                            this.selectedTopicId = this.$route.query.topicId;
                        } else {
                            this.selectedTopicId = this.topicList[0].id;
                        }

                        //等到DOM更新之后再开始执行
                        this.$nextTick(() => {
                            // console.log(this.$route.query.noteId);
                            if (this.$route.query.noteId) {
                                this.getNoteById(this.$route.query.noteId);
                            } else if (this.$route.query.chapterId) {
                                let chapter = {};
                                //筛选出对应的chapter
                                for (let i = 0; i < this.topicList.length; i++) {
                                    let chapterList = this.topicList[i].chapter_list;
                                    for (let j = 0; j < chapterList.length; j++) {
                                        if (chapterList[j].id == this.$route.query.chapterId) {
                                            chapter = chapterList[j];
                                            break;
                                        }
                                    }
                                }
                                this.listNote(chapter);
                            } else {
                                this.getFisrtNoteByTopicId(this.selectedTopicId);
                            }
                        });
                    }
                } else {
                    this.$message.error(response.data.message);
                }
            } catch (error) {
                console.log(error);
            }
        },
        handleClick(tab, event) {
            // console.log(tab, event);
            // console.log(tab.$el);
            // console.log(this.selectedTopicId);

            //判断url参数的topicId是否和选择的topicId相同，不同则修改参数并跳转
            if (this.$route.query.topicId != this.selectedTopicId) {
                const query = { topicId: this.selectedTopicId };
                this.$router.push({ path: this.$route.path, query });
            }

            // console.log(this.$route.path);
            this.getFisrtNoteByTopicId(this.selectedTopicId);
        },
        async getFisrtNoteByTopicId(topicId) {
            try {
                const response = await apiGetFisrtNoteByTopicId(topicId);
                if (response.data.code == 200) {
                    let note = response.data.data;
                    if (note) {
                        //加载笔记详情
                        document.getElementById(topicId).innerHTML = "<h1 style='margin-top: 0px;'>" + note.title + "</h1>" + note.text;
                        //上一个选中的笔记恢复原状
                        let dom = document.getElementById(this.selectedDOMID);
                        if (dom) {
                            dom.className = "";
                        }
                        this.selectedDOMID = note.id;
                        //选中的笔记标题加粗
                        document.getElementById(this.selectedDOMID).className = "active";
                    }
                    this.loading = false;
                } else {
                    this.$message.error(response.data.message);
                    this.loading = false;
                    document.getElementById(topicId).innerHTML = "加载失败，请重试！";
                }
            } catch (error) {
                console.log(error);
            }
        },
        async getNoteById(id) {
            //判断url参数中的noteId是否和传入的id相同，不同则修改url参数并跳转
            if (this.$route.query.noteId != id) {
                const query = JSON.parse(JSON.stringify(this.$route.query));
                query.noteId = id;
                this.$router.push({ path: this.$route.path, query });
            }

            //上一个选中的笔记恢复原状
            let dom = document.getElementById(this.selectedDOMID);
            if (dom) {
                dom.className = "";
            }
            this.selectedDOMID = id;
            //选中的笔记标题加粗
            document.getElementById(this.selectedDOMID).className = "active";
            //获取右方文本内容
            try {
                this.loading = true;
                const response = await apiGetNoteById(id);
                if (response.data.code == 200) {
                    let note = response.data.data;
                    // console.log(note);
                    if (note) {
                        //加载笔记详情
                        document.getElementById(this.selectedTopicId).innerHTML = "<h1 style='margin-top: 0px;'>" + note.title + "</h1>" + note.text;
                    }
                    this.loading = false;
                } else {
                    this.$message.error(response.data.message);
                    this.loading = false;
                    document.getElementById(this.selectedTopicId).innerHTML = "加载失败，请重试！";
                }
            } catch (error) {
                console.log(error);
                this.$message.error(error);
                this.loading = false;
                document.getElementById(this.selectedTopicId).innerHTML = "加载失败，请重试！";
            }
        },
        listNote(chapter) {
            //判断url参数中的chapterId是否和传入的chapter.id相同，不同则修改url参数并跳转
            if (this.$route.query.chapterId != chapter.id) {
                // const query = JSON.parse(JSON.stringify(this.$route.query));
                const query = { topicId: this.$route.query.topicId, chapterId: chapter.id };
                this.$router.push({ path: this.$route.path, query });
            }

            // console.log(chapter);
            //上一个选中的笔记恢复原状
            let dom = document.getElementById(this.selectedDOMID);
            if (dom) {
                dom.className = "";
            }
            this.selectedDOMID = chapter.id;
            //选中的笔记标题加粗
            document.getElementById(this.selectedDOMID).className = "active";
            if (chapter.note_list.length > 0) {
                //拼凑右方文本内容
                // <ul>
                //     <li><a></a></li>
                // </ul>
                document.getElementById(this.selectedTopicId).innerHTML = "";
                let ul = document.createElement("ul");
                ul.style = "list-style-type:circle;";
                for (let i = 0; i < chapter.note_list.length; i++) {
                    let li = document.createElement("li");
                    let a = document.createElement("a");
                    a.innerHTML = chapter.note_list[i].title;
                    a.style = "cursor: pointer;";
                    a.onclick = () => {
                        this.getNoteById(chapter.note_list[i].id);
                    };
                    li.appendChild(a);
                    ul.appendChild(li);
                }
                //加载右方文本内容
                document.getElementById(this.selectedTopicId).appendChild(ul);
            }
            this.loading = false;
        }
    },
    mounted() {
        this.listTopic();
    }
};
</script>
