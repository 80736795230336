import axios from "@/api/baseRequest.js";

//根据topicId获取该课题下的第一篇笔记
export const apiGetFisrtNoteByTopicId = function (id) {
    return axios.get("/api/1/pub/note/getFisrtNoteByTopicId", {
        params: {
            topicId: id
        }
    });
};

//根据ID获取笔记
export const apiGetNoteById = function (id) {
    return axios.get("/api/1/pub/note/getNoteById", {
        params: {
            id: id
        }
    });
};
